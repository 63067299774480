<template>
  <div class="about" v-if="init">
    <h1 v-if="offlineOrders.length > 0">! Offline orders</h1>

    <div v-if="offlineOrders.length > 0" class="admin-table orders-table">
      <div class="table-header">
        <span class="vismaid label">VismaID</span>
        <span class="dispatch-date label">Dispatch date</span>
        <span class="delivery-date label">Delivery date</span>
        <span class="created-at label">Created at</span>
        <span class="company label">Company</span>
        <span class="created-by label">Created by</span>
        <span class="order-note label">Order note</span>
      </div>
      <div
        v-for="order in offlineOrders"
        :key="order.id"
        @click="navigateToOrder(order.id)"
      >
        <span class="vismaid" v-if="order.vismaId.toString().length > 15"
          >none</span
        >
        <span class="vismaid" v-else>{{ order.vismaId }}</span>
        <span class="dispatch-date" v-if="order.dispatchDate">{{
          order.dispatchDate.toDate().getFullYear() +
          "-" +
          ("0" + (order.dispatchDate.toDate().getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + order.dispatchDate.toDate().getDate()).slice(-2)
        }}</span>
        <span class="dispatch-date" v-else></span>
        <span class="delivery-date" v-if="order.deliveryDate">{{
          order.deliveryDate.toDate().getFullYear() +
          "-" +
          ("0" + (order.deliveryDate.toDate().getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + order.deliveryDate.toDate().getDate()).slice(-2)
        }}</span>
        <span class="delivery-date" v-else></span>
        <span class="created-at">{{
          order.createdAt.toDate().getFullYear() +
          "-" +
          ("0" + (order.createdAt.toDate().getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + order.createdAt.toDate().getDate()).slice(-2) +
          " -- " +
          ("0" + order.createdAt.toDate().getHours()).slice(-2) +
          ":" +
          ("0" + order.createdAt.toDate().getMinutes()).slice(-2)
        }}</span>
        <span class="company">{{
          getCompanyNameByVismaId(order.company)
        }}</span>
        <span v-if="order.user !== 'vismaSync'" class="created-by">{{
          getUserEmailById(order.user)
        }}</span>
        <span v-else class="created-by">Visma sync</span>
        <span class="order-note">{{ order.orderNote }}</span>
      </div>
    </div>

    <h1>Orders</h1>
    <div class="admin-table orders-table">
      <div class="table-header">
        <span class="vismaid label">VismaID</span>
        <span class="dispatch-date label">Dispatch date</span>
        <span class="delivery-date label">Delivery date</span>
        <span class="created-at label">Created at</span>
        <span class="company label">Company</span>
        <span class="created-by label">Created by</span>
        <span class="order-note label">Order note</span>
      </div>
      <div
        v-for="order in regularOrders"
        :key="order.id"
        @click="navigateToOrder(order.id)"
      >
        <span class="vismaid">{{ order.vismaId }}</span>
        <span class="dispatch-date" v-if="order.dispatchDate">{{
          order.dispatchDate.toDate().getFullYear() +
          "-" +
          ("0" + (order.dispatchDate.toDate().getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + order.dispatchDate.toDate().getDate()).slice(-2)
        }}</span>
        <span class="dispatch-date" v-else></span>
        <span class="delivery-date" v-if="order.deliveryDate">{{
          order.deliveryDate.toDate().getFullYear() +
          "-" +
          ("0" + (order.deliveryDate.toDate().getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + order.deliveryDate.toDate().getDate()).slice(-2)
        }}</span>
        <span class="delivery-date" v-else></span>
        <span class="created-at">{{
          order.createdAt.toDate().getFullYear() +
          "-" +
          ("0" + (order.createdAt.toDate().getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + order.createdAt.toDate().getDate()).slice(-2) +
          " -- " +
          ("0" + order.createdAt.toDate().getHours()).slice(-2) +
          ":" +
          ("0" + order.createdAt.toDate().getMinutes()).slice(-2)
        }}</span>
        <span class="company">{{
          getCompanyNameByVismaId(order.company)
        }}</span>
        <span v-if="order.user !== 'vismaSync'" class="created-by">{{
          getUserEmailById(order.user)
        }}</span>
        <span v-else class="created-by">Visma sync</span>
        <span class="order-note">{{ order.orderNote }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Orders",
  data() {
    return {};
  },
  mounted() {},
  async created() {
    if (!this.init) {
      await this.$store.dispatch("orders/bindOrders");
      this.$store.commit("orders/setInit", true);
    }
  },
  beforeDestroy() {
    this.$store.dispatch("orders/unbindOrders");
    this.$store.commit("orders/setInit", false);
  },
  computed: {
    ...mapState({
      offlineOrders: (state) => state.orders.offlineOrders,
      init: (state) => state.orders.init,
    }),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    ...mapGetters("users", ["getUserEmailById"]),
    ...mapGetters("orders", ["regularOrders"]),
  },
  methods: {
    navigateToOrder: function (id) {
      this.$router.push({ name: "SingleOrder", params: { id } });
    },
  },
};
</script>
